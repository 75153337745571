import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from "@material-ui/core/Grid";
import './about.css';
import { Title } from 'react-admin';

export default function Contact() {

    const FirmContact = (props) => {
        return (<Card className="Card">
                <CardContent>
                    <div className="textcontentlarger">
                        {props.name}<br/>
                        {props.adress1}<br/>
                        {props.adress2}<br/>
                    </div>
                </CardContent>
            </Card>
        );
    };

    const PersonContact = (props) => {
        return (<Card className="Card">
                <CardContent>
                    <div className="textcontent">
                        {props.name}<br/>
                        {props.role}<br/>
                        Telephone {props.phone}<br/>
                        <a href={'mailto:' + props.email}>{props.email}</a><br/>
                    </div>
                </CardContent>
            </Card>
        );
    };
    
    const PersonContactNoPhone = (props) => {
        return (<Card className="Card">
                <CardContent>
                    <div className="textcontent">
                        {props.name}<br/>
                        {props.role}<br/>                      
                        <a href={'mailto:' + props.email}>{props.email}</a><br/>
                    </div>
                </CardContent>
            </Card>
        );
    };

    const SupportContact = (props) => {
        return (<Card className="Card">
                <CardContent>
                    <div className="textcontent">
                        Support<br/>
                        <a href={'mailto:' + props.email}>{props.email}</a><br/>                        
                    </div>
                </CardContent>
            </Card>
        );
    };
    
    const GeneralInfoContact = (props) => {
        return (<Card className="Card">
                <CardContent>
                    <div className="textcontent">
                        General Information<br/>
                        <a href={'mailto:' + props.email}>{props.email}</a><br/>                      
                    </div>
                </CardContent>
            </Card>
        );
    };

    return <>
        <Title title="ANNA Service Bureau Contacts"/>
        <Grid item xs>
            <Grid container spacing={2}>
                <Grid item xs>
                    <CardContent>                        
                        <SupportContact email="asbsupport@anna-web.org"
                                        lines={['National Numbering Agency', 'www.anna-web.org']}/>
                    </CardContent>
                </Grid>
                <Grid item xs>
                    <CardContent>                        
                        <GeneralInfoContact email="secretariat@anna-web.org"
                                        lines={['ANNA', 'Avenue Marnix 13-17', '1000 Bruxelles, Belgium']}/>
                    </CardContent>
                </Grid>
            </Grid>
        </Grid>
    </>;
}
